import wowIcon from "../assets/wow-icon-64.webp";
import banner4 from "../assets/banner4.webp";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const HomeBanner = ({ screenshotNumber = null }) => {
  return (
    <div className="home-banner">
      <i className="home-banner__angle fa fa-angle-down"></i>

      <div className="home-banner__version">
        <img
          className="home-banner__icon fa fa-image"
          src={wowIcon}
          alt="wow icon"
        />
        <span> version 1.1 to 1.12 original screenshots</span>
      </div>
      <h1>Daribon Screenshots Archive</h1>
      <h2>Browse World of Warcraft Vanilla Screenshots</h2>

      <img className="home-banner__cover" src={banner4} alt="home banner"></img>

      <div className="home-banner__description-box">
        <p>
          <a href="https://github.com/Daribon/Vanilla-Screenshot-Archive">
            Vanilla Archive
          </a>{" "}
          is an archive of images taken between 2004-2006 in the vanilla version
          of World of Warcraft.
        </p>
        <p>
          In order to build the archive, thousands of original vanilla
          screenshots have been found and archived.
        </p>
        <p>
          This website helps people interact with these screenshots that are{" "}
          <a href="https://github.com/Daribon/Vanilla-Screenshot-Archive">
            stored on GitHub
          </a>{" "}
          without the need to use it.
        </p>
      </div>

      <div className="home-banner__button-box">
        <Link to="/upload/">
          <button className="button-base button-home">
            Upload screenshots
          </button>
        </Link>
        <Link to="/browse/">
          <button className="button-base button-home">Browse Archive</button>
        </Link>
      </div>

      <p className="home-banner__check-sentence">
        <i className="fa fa-check"></i>
        We have{" "}
        <b>{screenshotNumber ? screenshotNumber : "18000"} screenshots</b>
      </p>
    </div>
  );
};

HomeBanner.propTypes = {
  screenshotNumber: PropTypes.number,
};
